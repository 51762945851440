import './App.css';

import SearchNames from './SearchNames.js'
import AddNewName from './addNewName.js'
import About from './aboutUs.js'

import {BrowserRouter, Routes, Route} from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

// Includes the header bar
function App() {
  return(
    <BrowserRouter>
      <Header />
        <Routes>
          <Route path="/" element={<SearchNames />} />
          <Route path="/SubmitName" element={<AddNewName />} />
          <Route path="/About" element={<About />} />
        </Routes>
    </BrowserRouter>
  )
}

function Header () {
  return(
    <Navbar bg="light" expand="lg" sticky="top">
      <Container fluid>
        <Navbar.Brand href="#">
          <img src="/favicon.ico" width="45" height="45" alt=""/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll"/>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '150px' }}
            navbarScroll
          >
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/SubmitName">Submit A Name</Nav.Link>
            <Nav.Link href="/About">About</Nav.Link>
          </Nav>
        </Navbar.Collapse> 
      </Container>
    </Navbar>
  )
}

export default App;
