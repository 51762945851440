import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDocs, getDocsFromCache} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import './SearchNames.css';
import FeedbackPop from './FeedbackPop.js';
import PhonoPop from './PhonoPop.js';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

// import {IoInformationCircleOutline} from "react-icons/io5";

function Main () {
    const [hasAudio, setAudio] = useState(0);
    const [usePhon, setPhon] = useState(0);
    const [genderAssociated, setGenderAssociated] = useState(-1);
    const [curNames, setCurNames] = useState([]);
    const refCollection = collection(db, "Approved");

    useEffect(() => {
      getCurNames();
    }, []);

  // retrieves names from firebase. If there is data in the cache, prevents retrieving again and loads from cache
    const getCurNames = async () => {
      try {
        const data = await getDocsFromCache(refCollection);
        setCurNames(data.docs.map((doc) => (doc.data())));
        // Document was found in the cache
        if (data.empty) {
          console.log("Error getting cached documents")
          const data = await getDocs(refCollection);
          setCurNames(data.docs.map((doc) => (doc.data())));
        }
        else{
          console.log("got documents from cache");
        }
      }
      catch(e) {
        console.log("Error getting cached documents")
        const data = await getDocs(refCollection);
        setCurNames(data.docs.map((doc) => (doc.data())));
      }
    };

    return(
    <div className="App">
        <div className="App-header">Diverse Names Generator</div>
        {SubmitForm(genderAssociated, setGenderAssociated, hasAudio, setAudio, usePhon, setPhon, curNames)}
    </div>
    )
}

function SubmitForm( genderAssociated, setGenderAssociated, hasAudio, setAudio, usePhon, setPhon, curNames) {
    const [checked, setChecked] = useState(true);
    const [query, setQuery] = useState("");
    
    // reset triggers all filter entereies to return to their default
    const reset = () => {
      setGenderAssociated(-1);
      setPhon(0);
      setQuery("");
      setAudio(0);
    }

    return (
      <div className="container-fluid">
        <div className="submit-form">
          <div className="row">
            <div className="mx-auto my-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
            {SearchBar({query, setQuery})}
            <h4 style={{margin:10,marginBottom:5}}>Gender:</h4>
            <select select onChange={e => {setGenderAssociated(e.target.value); setChecked(!checked);}} >
              <option id="np" name="gender" value="-1"
                selected={genderAssociated == -1 ? true : false}>No preference</option>
            <label>No preference</label>
              <option id="gf" name="gender" value="1" selected={genderAssociated == 1 ? true : false}
              >Generally Feminine</option>
              <label>Generally Feminine</label>
              <option id="gm" name="gender" value="0" selected={genderAssociated == 0 ? true : false}
              >Generally Masculine</option>
              <label>Generally Masculine</label>
              <option id="gn" name="gender" value="2" selected={genderAssociated == 2 ? true : false}
              >Gender Neutral</option>
              <label>Gender Neutral</label>
            </select>
              <h4 style={{margin:10,marginBottom:5}}>Audio Recording:</h4>
              <div>
                <input type="radio" name="recording" value="No Preference"
                  onClick={()=>{setAudio(false); setChecked(!checked); console.log("Audio: " + hasAudio)}}
                  checked={!hasAudio} />
              <label>No Preference</label>
              </div>
              <div>
                <input type="radio" name="recording" value="Has Recording"
                onClick={()=>{setAudio(true)}} checked={hasAudio}/>
                <label>Has Recording</label>
              </div>
              <PhonoPop/>             
              <select select onChange={e => {setPhon(e.target.value); setChecked(!checked);}}>
                <option id="nopref" name="phono" value="0" selected={usePhon == 0 ? true : false}
                  >No preference</option>
              <label>No preference</label>
                <option id="eng" name="phono" value="1" selected={usePhon == 1 ? true : false}
                >English</option>
                <label>English</label>
              </select>
              <div>
              <br></br>
              <button className ="btn btn-secondary"
                  onClick={reset}
                  onSubmit={reset}>Clear
              </button>
              </div>
            </div>
            <div className="mx-auto col-lg-7 col-md-7 col-sm-7 col-xs-12">
              {Results(genderAssociated, hasAudio, usePhon, curNames, query)}    
            </div>
          </div>
          <Navbar bg="" expand="true" fixed="bottom">
            <Container>
              <Nav className="mx-auto" style={{marginBottom:10, width:'100px', 'relative z-index': '1000'}}>
                <button className ="clickable btn btn-primary"
                  onClick={(e)=>{setChecked(!checked); e.preventDefault();}}
                  onSubmit={e => { e.preventDefault(); }}
                >Generate</button>
              </Nav>
            </Container>
          </Navbar>
        </div>
      </div>
    );
  }

function SearchBar({ query, setQuery }){

   return(
    <div style={{marginTop:20}}>
      <form method="get" onSubmit={e => { e.preventDefault(); }}>
          <label htmlFor="header-search" className="visually-hidden">Starts With</label>
          <h4 style={{margin:10,marginBottom:5}}>Starts With:</h4>
          <input 
              value={query}
              onInput={e => setQuery(e.target.value)}
              type="text"
              id="header-search"
          />
      </form>
    </div>
    );

}

function Results ( genderAssociated, hasAudio, usePhon, curNames, query ) {

  // const [onlyName, setOnlyName] = useState(false);
  var onlyName = false;
  var history = [];
  
  Array.prototype.sample = function(){
    onlyName = this.length == 1;
    console.log('sampled', this);
    return this[Math.floor(Math.random()*this.length)];
    var res = this[Math.floor(Math.random()*this.length)];
    console.log(res, Math.floor(Math.random()*this.length), this.length);
    // onlyName = this.length == 1;
    // return res;
    if (this.length == 1){
      onlyName = this.length == 1;
      return res;
    }
    onlyName = false;
    if (this.length > 5){
      while (history.includes(res)){
        res = this[Math.floor(Math.random()*this.length)];
      }
    }else{
      if (history.length == curNames.length){
        history.push(history.shift());
        return history[history.length-1];
      }else{
        while (history.includes(res)){
          res = this[Math.floor(Math.random()*this.length)];
        }
      }
    }
    history.push(res);
    return res;
  }
  
  // filters all names to only return a set of names which match the filters set by user
  const getResult = function(){
    return(curNames
    .filter((a)=>(a.name.toLowerCase().startsWith(query.toLowerCase())))
    .filter((a) => (genderAssociated == -1 || (a.genderPreference) == genderAssociated))
    .filter((a) => (hasAudio == 0 || a.audioPerms == hasAudio))
    .filter((a) =>(usePhon == 0 || a.engPhon == usePhon))
    .sample())
 }
  
  return (
    <div>
      <Output value={getResult()} oneElem={onlyName}/>
    </div>
  )
 }
 

function Output( props ) {
  const [url, setUrl] = useState();
  const getGender = ["Generally masculine", "Generally feminine", "Gender neutral"];
  
  useEffect(() => {
    const getCurStorage = async () => {
      console.log("downloading audio for name")
      const storage = getStorage();
      let str = props.value.audio;
      let audioRef = ref(storage, str);
      console.log(str)
      // Get the download URL for the audio, which is a reference to storage in firestore
      getDownloadURL(audioRef)
        .then((url) => {
          setUrl(url)
        })
    };
    getCurStorage();
  }, [props]);

  try{
  return (
    <div>
    <div className="resultRet">
    <span className="bold">{props.value.name}</span>
    <ul>
      <li>IPA: {props.value.ipa[0]=='[' ? props.value.ipa : ("[ " + props.value.ipa + " ]")}</li>
      <li>{getGender[props.value.genderPreference]}</li>

      {(props.value.orthography) ? <li style={{ "font-size": "18px" }}>Non-Roman Orthography: {props.value.orthography}</li> : null}
      <li style={{ "font-size": "18px" }}>Language: {props.value.languagePrimary}</li>
      {(props.value.famous !== "") ? <li style={{ "font-size": "18px" }}>Famous People: {props.value.famous}</li> : null}
      {(props.value.notes !== "") ? <li style={{ "font-size": "18px" }}>{props.value.notes}</li> : null}
    </ul>
    {props.value.audioPerms == true ?
    <audio id="audio" key={url} controls>
      <source src={url} />
    </audio>
    : null}
    </div>
    {(props.oneElem == true) ? <h4 className="oneElem">Only one name found</h4> : null}
    {(props.value.creditToName.length > 0) ? <div><em style={{ "font-size": "20px" }}>name submitted by {props.value.creditToName} </em></div> : null}
    <FeedbackPop value={props.value}/>
    </div>
  )
  } catch(e){
    console.log(e)
    return(<div><em>No name found, try using different options!</em></div>)
  }
}

export default Main;