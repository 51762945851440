import React, { useState, useEffect} from 'react';
import './aboutUs.css';
import Accordion from 'react-bootstrap/Accordion';

// About page with dropdown QnA
function About (){
    return(
        <div class="App">
        <div class="App-header">Diverse Names Generator</div>
        <br></br>
        <br></br>
        <div class="container-fluid">
        <div class="row mx-auto justify-content-center">
        <div className="col-10">
        <br></br>
        <div class="subtitle center">Linguists study the diversity of human language. We are, ourselves, a diverse group of people. The students we teach are a diverse group of people. So why not also use diverse proper names in our example sentences?</div>
        <br></br>
        <div class="subtitle center">The Diverse Names Generator (DNG) provides randomly selected proper names with IPA transcriptions from a user-contributed, linguist-curated database of names from a wide range of languages and cultures. Generating names randomly helps users to overcome unconscious biases that may lead them to default to patterns like using Anglophone names or using male names as subjects and female names as objects. </div>
        <br></br>
        <div class="subtitle center">We also have <a href="https://play.google.com/store/apps/details?id=com.DefaultCompany.DiverseNameGenerator&pli=1">an android app</a> available for download on the Google play store, a <a href="https://docs.google.com/presentation/d/1k5Rr67U9rnGMUW8K15CbXCag9mupifoXtu0owwADkpE/edit?usp=sharing"> presentation </a> and <a href="https://doi.org/10.3765/plsa.v8i1.5541">paper</a> detailing our project!</div>
        <br></br>
        <div class="subtitle center">The Diverse Names Generator was created by <a href="https://www.mauraoleary.org/">Maura O’Leary</a> (Swarthmore College), <a href="https://www.linkedin.com/in/raineywilliams/">Rainey Williams</a> (UCLA), <a href="https://www.mariopenglee.com">Mario Peng Lee</a> (UCLA), and <a href="https://www.linkedin.com/in/seancunin/">Sean Cunin</a> (UCLA).</div>
        <br></br>
        <div class="subtitle center">Many thanks go to Elise Bell and her students for submitting nearly 200 names to our database. Thank you also
to our colleagues who acted as sounding boards, support systems, and beta testers, including Jessica Rett, Nathan
Sanders, Pocholo Umbal, and Lex Konnelly. We are likewise grateful for the feedback of three anonymous LSA
reviewers and audiences at the LSA 2023 Annual Meeting and the UCLA Bruin Linguists Society. Finally, thanks go
to the UCLA Center for Advancement of Teaching; the UCLA Linguistics Department; and the UCLA Undergraduate Research Center for Humanities, Arts, and Social Sciences for their financial support.</div>
        <br></br>
        <div class="subtitle center">Contact us at diversenamesgenerator@gmail.com for any questions or comments.</div>
        <br></br>
        <Accordion>
        <Accordion.Item eventKey="0">
        <Accordion.Header>Why bother with a generator? Don’t we all know enough names already?</Accordion.Header>
        <Accordion.Body>
        <div class="subtitle center">Using diverse names in the classroom is critical to help students from feeling disrespected and dehumanized when names from their cultures are seen as “too hard” or “too foreign.” It is important for all students to feel included and represented, to help prevent members of under-represented ethnocultural groups, as well as women and people of other under-represented gender identities, from falling out of the “leaky pipeline” of academia. However, despite the importance of using diverse names, studies have consistently shown that the example sentences used by many linguists still tend strongly towards male-gendered names (Macaulay & Brice 1997; Cépeda, Kotek, Pabst, & Syrett 2021; Kotek, Dockum, Babinski, & Geissler 2021) and “reflect the dominant white culture far more than minoritized races and ethnicities” (Kotek et al. 2021). Surveys of linguistics textbooks from 1969-1994 (Macaulay & Brice 1997) and 2005-2017 (Cépeda et al. 2021) show continuously perpetuated gender stereotypes, and, strikingly, that “male-gendered arguments are almost twice as frequent as female-gendered ones.”(Cépeda et al. 2021) Surveys of papers in major peer-reviewed linguistics journals over the last 20 years revealed the same patterns (Kotek et al. 2021), showing that the problem is pervasive in many areas of our field.</div>
        <br></br>
        <div class="subtitle center">Even if we have good intentions to use names that represent diverse cultures and gender identities, it’s very easy to fall into a pattern of using non-diverse names or simply being affected by unconscious bias. We propose that letting a generator randomly pick a name for you helps to eliminate any unconscious bias and promote diversity in our field.</div>
        <br></br>
        <div class="subtitle center">Some reading on this subject:</div>
        <ul>
            <li>Bucholtz. (2016). On Being Called Out of One’s Name: Indexical Bleaching as a Technique of Deracialization.</li>
            <li>Cépeda, Kotek, Pabst, & Syrett. (2021). Gender bias in linguistic textbooks.</li>
            <li>Goulden, Mason, & Frasch. (2011). Keeping Women in the Science Pipeline.</li>
            <li>Holmes & Menachemi. (2017). Retention of Minority Faculty: Does a Leaky Pipeline Threaten Achieving Diversity in Academic Health Administration?</li>
            <li>Konnelly, Umbal, & Sanders. (2021). The diverse names database: A tool for creating more equitable, diverse, and inclusive linguistic example sentences.</li>
            <li>Kotek, Dockum, Babinski, & Geissler. (2021). Gender bias and stereotypes in linguistic example sentences.</li>
            <li>Macaulay & Brice. (1997). Don’t touch my projectile: Gender bias and stereotyping in syntactic examples</li>
            <li>McMaster. (2020). Getting foreign names right matters, psychology study shows.</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Who is this for?</Accordion.Header>
        <Accordion.Body>
        <div class="subtitle center">This resource is for anyone who uses proper names in their work. Some good uses we can think of:</div>
        <br></br>
        <ul>
            <li>example sentences in linguistics classes</li>
            <li>example sentences in linguistics research</li>
            <li>fiction writing</li>
            <li>example sentences in other fields, such as in “word problems” in math classes</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How do I use it?</Accordion.Header>
        <Accordion.Body>
        <div class="subtitle center">Just click “generate”! If you would like to constrain the names generator, you can narrow the pool by using our search filters:</div>
        <br></br>
        <ul>
            <li>gender most commonly associated with the name (whether the name is generally used as a masculine or feminine name, or if it is totally gender neutral). This is useful if you need certain gendered pronouns to match phi features in a syntax or morphology class</li>
            <li>first letter(s) of the name. This is useful for semantics classes, where you might want to keep every name associated with a different starting letter (e.g., for translation into predicate logic).</li>
            <li>has audio recording. If you want to be able to hear what the name sounds like, filter out names that do not have an accompanying recording.</li>
            <li>“phonotactics of…” Get names that only use the phonemes and phonotactics that are familiar to you. If you are able to help us add more languages to this function, please contact us at  diversenamesgenerator@gmail.com. </li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Where do the names come from?</Accordion.Header>
        <Accordion.Body>
        <div class="subtitle center">Names are user submitted! You can submit your own via the “submit a name” tab at the top of the screen. We hand check all submissions about once a month and then add them to our database.</div>
        <br></br>
        <div class="subtitle center">If you would like your students to get extra credit for submitting names to our database, please contact us at diversenamesgenerator@gmail.com.</div>
        <br></br>
        <div class="subtitle center">We are working on adding graphs to this page that will show the current gender and cultural diversity of the names in our database at any given time.</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Where can I learn more about this project?</Accordion.Header>
        <Accordion.Body>
        <div class="subtitle center">You can learn more about this project from our <a href="https://doi.org/10.3765/plsa.v8i1.5541">paper</a>, or you can email us at diversenamesgenerator@gmail.com. </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>I submitted a name – why don’t I see it?</Accordion.Header>
        <Accordion.Body>
        <div class="subtitle center">Each name submission is hand-checked by our team. This process happens roughly once a month. The website and app then will update within a day after approval.</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>I’m getting a message that says “no names found.” What’s up with that?</Accordion.Header>
        <Accordion.Body>
        <div class="subtitle center">If you are getting this message, it most likely means that your search filters are too restrictive. Try loosening them to generate more names.
        If you have no filters on and you are still getting this message, it means our database has been overwhelmed by too many users in one day. This should reset on the next calendar day, but feel free to let us know that it’s happening by emailing us at diversenamesgenerator@gmail.com.
        </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>Can I use the generator offline?</Accordion.Header>
        <Accordion.Body>
        <div class="subtitle center">Yes! Put in your search terms and hit “generate” once (on either the website or the app). That search will then be accessible offline until you refresh the page or try different search filters (which require internet access). Note that recordings are not available offline.</div>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
        </div>
    </div>
    </div>
    <br></br>
    </div>
    )
}

export default About;