import React, { useState } from 'react';
import './Pop.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {IoInformationCircleOutline} from "react-icons/io5";
import Modal from 'react-bootstrap/Modal';

// when called, retrieves all data for currently displayed name. allows for edits
function Main () {
    const [isPopped, togglePop] = useState(false);
    return(
        <div>
            <h4 style={{margin:10,marginBottom:5}}>Phonotactics of... <em class="llink" onClick={() => togglePop(!isPopped)}><IoInformationCircleOutline/></em></h4>
            {isPopped ? <PopUp isPopped={isPopped} togglePop={togglePop}/> : null}
        </div>
    )
}

function PopUp (props) {
    const handleClose = () => props.togglePop(false);
    const handleShow = () => props.togglePop(true);
  
    return(
        <div>
        <Modal show={handleShow} onHide={handleClose} centered>
          <Modal.Header closeButton>
            {/* <Modal.Title>Phonotactics</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>Sort results so that you get names that follow the phonotactics of a language you are comfortable with. If you are able to help us add more languages to this function, please contact us at  diversenamesgenerator@gmail.com.</Modal.Body>
        </Modal>
      </div>
    )
}

export default Main;