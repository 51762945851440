import React, { useState, useEffect, useCallback } from 'react';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import vmsg from "vmsg";
import './addNewName.css';
import { db } from './firebase';
import { collection, doc, setDoc, getDocs, getDocsFromCache } from 'firebase/firestore';

//For spreadsheets
//import { GoogleSpreadsheet } from 'google-spreadsheet';
//import { JWT } from 'google-auth-library'

function Main () {
  const [formType, setFormType] = useState(0);
    return(
    <div class="App">
        <div class="App-header">Diverse Names Generator</div>
        <div class="container-fluid">
        <div class="row mx-auto justify-content-center">
          <div className="col-10">
            <br></br>
            <div class="subtitle center">Thank you for helping us to improve the Diverse Names Generator!</div>
            <br></br>
            <div class="subtitle center">Please use the questions below to add a name to our database. We especially appreciate names from underrepresented cultures and linguistic groups. Please use names that you are familiar with and know how to pronounce. We are only looking for first/non-family names.</div>
          <div></div>             <br></br>
          <label> Are you filling this out for class credit? *</label>
          <div></div>
          <select onChange={e => {setFormType(e.target.value)}}>
            <option selected disabled value="0"></option>
            <option value="2">Class Credit</option>
            <option value="1">No Class Credit</option>
          </select>
          </div>
            {formType == 0 ? null : <SubmitForm value={formType} setFormType={setFormType}/>}
        </div>
        </div>
    </div>
    )
}

function SubmitForm(props) {
  const [nameRoman, setNameRoman] = useState("");
  const [nameOK,setNameOK] = useState(true);
  const [nameNonRoman, setNameNonRoman] = useState("");//optional
  const [IPATrans, setIPATrans] = useState("");
  const [waveOne, setWaveOne] = useState("");
  
  const [canAudio,setCanAudio] = useState(false);
  const [altName, setAltName] = useState("");
  const [altPron, setAltPron] = useState("");
  const [genderAssociated, setGenderAssociated] = useState(-1);
  const [waveTwo, setWaveTwo] = useState("");
  const [lang, setLang] = useState("");
  const [altLang, setAltLang] = useState("");
  const [cult, setCult] = useState("");
  const [fame, setFame] = useState("");
  const [note, setNote] = useState("");
  const [recogName, setRecogName] = useState("");
  const [EC_name, setEC_name] = useState("");
  const [EC_school, setEC_school] = useState("");
  const [audioDetails, setAudioDetails] = useState([]);
  const [curNames, setCurNames] = useState([]);
  const [useRecorder, setUseRecorder] = useState(true);

  const refCollection = collection(db, "Approved");

  // getting names from firebase once in initial page load
  useEffect(() => {
    getCurNames();
  }, []);

  // retrieves names from firebase. If there is data in the cache, prevents retrieving again and loads from cache
  const getCurNames = async () => {
    try {
      const data = await getDocsFromCache(refCollection);
      setCurNames(data.docs.map((doc) => (doc.data().name.toLowerCase())));
      // Document was found in the cache
      if (data.empty) {
        console.log("Error getting cached documents")
        const data = await getDocs(refCollection);
        setCurNames(data.docs.map((doc) => (doc.data().name.toLowerCase())));
      }
      else {
        console.log("got documents from cache");
      }
    }

    catch(e) {
      console.log("Error getting cached documents")
      const data = await getDocs(refCollection);
      setCurNames(data.docs.map((doc) => (doc.data().name.toLowerCase())));
    }
  };

  // checks the status of a name upon entry. if name "OK" (is not currently in our approved database) then we allow it to be submitted. 
  const checkNames = (e) => {
    if (e === "") {
      return;
    }
    if (curNames.includes(e.toLowerCase().trimEnd())) {
      console.log('found name')
      setNameOK(false);
      setNameRoman(e);
    }
    else {
      console.log('name not present')
      setNameOK(true);
    }
  };

  //create a new google sheet
  // function create(title, callback) {
  //   try {
  //     gapi.client.sheets.spreadsheets.create({
  //       properties: {
  //         title: title,
  //       },
  //     }).then((response) => {
  //       if (callback) callback(response);
  //       console.log('Spreadsheet ID: ' + response.result.spreadsheetId);
  //     });
  //   } catch (err) {
  //     document.getElementById('content').innerText = err.message;
  //     return;
  //   }
  // }

  // upon using clicking submit, send name to firebase, audio to storage
  const handleSubmit = async (evt) => {

    evt.preventDefault();
    if(audioDetails.length == 0 && props.value == 2){
      alert("You must submit an audio recording")
      return false
    }
    const date = Date.now();

    const data = {
      name: nameRoman,
      orthography: nameNonRoman,
      ipa: IPATrans,
      audioPerms:canAudio,
      audio: nameRoman + ".wav",
      altName: altName,
      altIpa: altPron,
      altAudio: nameRoman + "alt.wav",
      genderPreference: genderAssociated,
      languagePrimary: lang,
      languagesOther: altLang,
      cultures: cult,
      famous: fame,
      notes: note,
      creditToName: recogName,
      ecName: EC_name,
      ecClass: EC_school,
      engPhon: false
    };
    await setDoc(doc(db, "NeedApproval", nameRoman + "_" + date), data); //set to "NeedApproval" for final

    //sending audio
    const metadata = {
      contentType: 'audio/wav',
    };
    const storage = getStorage();

    if(audioDetails.length !== 0)
    {   let ch = audioDetails;
        console.log(audioDetails);
        let nm = "underReview/" + nameRoman + "_" + date + ".wav";

        let storageRef = ref(storage, nm);
        await (uploadBytes(storageRef, ch, metadata).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        }))
    }
    //sending alt audio
    if(waveTwo.length !== 0)
    {   let ch = waveTwo;
        console.log(waveTwo);
        let nm = "underReview/" + nameRoman + "alt_" + date + ".wav";
        let storageRef = ref(storage, nm);
        await (uploadBytes(storageRef, ch, metadata).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        }))
    }
    return true
  }

  // Upon name submission, reload winder and alert user of success
  const reload = async (evt) => {
    if (await handleSubmit(evt)) {
      window.location.reload();
      alert("name submitted!")
    }
  }
  // forms for adding a new name. Different options render depending on the form type (props.value) 1=non-class, non-expanded, 2=class, 3=non-class, expanded
  return (
    <div className="col-10">
          <form onSubmit={reload}>
      {console.log('the form type is', props.value)}
      <div class="form-group">
        <label>What name are you submitting? Please use only Roman characters (diacritics are fine). Please only submit ONE name per form submission. *</label>
        {nameOK === true ?
          <input type="text" className="form-control" placeholder="Please fill out this form for only one name submission at a time" value={nameRoman} onChange={e => {setNameRoman(e.target.value); checkNames(e.target.value);}} style={{color: "green"}} required/>
        : <input type="text" className="form-control" placeholder="Please fill out this form for only one name submission at a time" value={nameRoman} onChange={e => {setNameRoman(e.target.value); checkNames(e.target.value);}} style={{color: "red"}} required/>
        }
        {nameOK === false?<b>This name is already in our database! Please submit a new name.</b>:null}
      </div>
      <div class="form-group">
        <label>Please provide an IPA transcription for the name you are submitting, including stress and tone markers. Please use <a href="https://ipa.typeit.org/full/">this site</a> to transcribe, and then copy and paste your transcription. *</label>
        <input type="text" className="form-control" value={IPATrans} onChange={e => setIPATrans(e.target.value)} required/>
      </div>
      <div class="form-group">
        <label> What gender is associated with this name? *</label>
        <div></div>
        <select onChange={e => {setGenderAssociated(e.target.value)}}>
        <option selected disabled></option>
          <option value="0">Generally Masculine</option>
          <option value="1">Generally Feminine</option>
          <option value="2">Generally Gender-Neutral</option>
        </select>
      </div>
      <div class="form-group">
      <label>What language(s) is this name associated with? *</label>
      <input type="text" placeholder="Type and separate with commas" className="form-control" value={lang} onChange={e => setLang(e.target.value)} required/>
      </div>
      {console.log('the form type is', props.value)}
      {props.value == 1 || props.value == 3 ?
      <div>
        <p>Please record yourself pronouncing this name so that we can check your IPA transcription. </p>
        <RecApp setAudioDetails={setAudioDetails}/>
      <br></br>
      <div class="form-group">
        <label>We may offer an audio reference for some names. If so, do you consent for the recording(s) you submit to be used on our website/app? You are not obligated to let us use your recording(s).</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio00" value="option00" onClick={e => setCanAudio(true)}/>
          <label class="form-entry form-check-label" for="inlineRadio00">yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio2" value="option0" onClick={e => setCanAudio(false)}/>
          <label class="form-entry form-check-label" for="inlineRadio0">no</label>
        </div>
      </div>
      </div>
      :
      null
      }
      {props.value > 1 ? 
        <div>
      <div class="form-group">
        <label>If this name is generally written using non-Roman characters, please supply the name in that orthography here:</label>
        <input type="text" className="form-control" value={nameNonRoman} onChange={e => setNameNonRoman(e.target.value)}/>
      </div>
      {props.value == 2
      ?
      <div>
      <div class="form-group" required>
    <label>We may offer an audio reference for some names. If so, do you consent for the recording(s) you submit to be used on our website/app? You are not obligated to let us use your recording(s), even if you are completing this for course credit. *</label>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio00" value="option00" onClick={e => setCanAudio(true)}/>
      <label class="form-entry form-check-label" for="inlineRadio00">yes</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio2" value="option0" onClick={e => setCanAudio(false)}/>
      <label class="form-entry form-check-label" for="inlineRadio0" >no</label>
    </div>
    </div>
      {useRecorder ?
      <div>
        <p>Please record yourself pronouncing this name so that we can check your IPA transcription. *</p>
        <RecApp setAudioDetails={setAudioDetails}/>
      </div>
      :
      <div class="form-group">
        <label>Please upload a recording (.wav, .mp3) of yourself pronouncing this name so that we can check your IPA transcription. Please label the file with (only) the name that you are submitting. *</label>
        <input type="file" id="file" name="file" value={waveOne} onChange={e => setWaveOne(e.target.value)} accept="audio/wav" required/>
      </div>
      }
    </div>
      :
      <div>
    </div>}
    <div class="form-group">
      <label>Are there any alternate spellings for this name? Are there any lengthened or shortened forms of this name? If so, please enter them here, separated by commas. If none, leave blank.</label>
      <input type="text" placeholder="e.g. Chris, Kris, Christopher" className="form-control" value={altName} onChange={e => setAltName(e.target.value)}/>
    </div>
    <div class="form-group">
      <label>Are there any alternate pronunciations? If so, please enter them here, separated by commas. This is also a good place to enter a more broad/narrow transcription than you used above. Please use <a href="https://ipa.typeit.org/full/">this site</a> to transcribe, and then copy and paste your transcription. If none, leave blank.</label>
      <input type="text" className="form-control" value={altPron} onChange={e => setAltPron(e.target.value)}/>
    </div>
    <div class="form-group">
      <p>Please upload a recording of yourself pronouncing alternate pronunciations so that we can check your IPA transcription.</p>
      <p class="audbutton"><RecApp setAudioDetails={setWaveTwo}/></p>
    </div>
    <div class="form-group">
      <label>What culture(s) (if not included in languages) is this name associated with?</label>
      <input type="text" placeholder="Type and separate with commas. American, Filipino, Korean Christian" className="form-control" value={cult} onChange={e => setCult(e.target.value)}/>
    </div>
    <div class="form-group">
      <label>Are there any famous instances of this name? For instance, for the name "Abraham" you might write "Abraham is a well known religious figure for Christianity, Judaism, and Islam. Abraham Lincoln was a famous US president." If none, leave blank.</label>
      <input type="text" placeholder="e.g. a major historical figure, celebrity, or fictional character" className="form-control" value={fame} onChange={e => setFame(e.target.value)}/>
    </div>
    <div class="form-group">
      <label>Any other notes about this name? If none, leave blank.</label>
      <input type="text" className="form-control" value={note} placeholder="e.g. Dana used to be a male name but is now mostly used for females. or Ester is a name mostly seen in older generations." onChange={e => setNote(e.target.value)}/>
    </div>
    <div class="form-group">
      <label>If you would like recognition in our app for submitting this name, what is your name? (Note that giving your name here will not help for course credit purposes.)</label>
      <input type="text" className="form-control" value={recogName} onChange={e => setRecogName(e.target.value)}/>
    </div>
    {props.value == 2 ?
    <div>
    <div class="form-group">
      <label>If you need course credit, what is your name? 
      (Note that giving your name here will not result in your name being listed in the app in any way.)</label>
      <input type="text" className="form-control" value={EC_name} onChange={e => setEC_name(e.target.value)}/>
    </div>
    <div class="form-group">
      <label>If you need course credit, what is your school, course code, and term?</label>
      <input type="text" className="form-control" placeholder="e.g. UCLA, LING103, Fall_2021" value={EC_school} onChange={e => setEC_school(e.target.value)}/>
    </div>
    </div>
    : null}
        </div>
      : null}
      {props.value == 1 ?
        <p class="link" onClick={e => {props.setFormType(3)}}>Add more info or get credit for your submission</p>
        : null}
      <input type="submit" value="Submit" style={{marginBottom:30}}/>
      </form>
    </div>
  );
}

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
});

//RecordingApp 
function RecApp (props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const [audio, setAudio] = useState([]);
  const [hasRecording, setHasRecording] = useState(false);

  const record = async () => {
      setIsLoading(true)
      if (hasRecording) {
          setIsLoading(false);
          setIsRecording(false);
          setRecordings([]);
          setAudio([])
          setHasRecording(false)
          props.setAudioDetails([]);
      }
      else {
          if (isRecording) {
              const blob = await recorder.stopRecording();
              setIsLoading(false)
              setIsRecording(false)
              setRecordings(recordings.concat(URL.createObjectURL(blob)))
              setAudio(blob)
              setHasRecording(true)
              props.setAudioDetails(blob);
          }
          else {
              try {
                  await recorder.initAudio();
                  await recorder.initWorker();
                  recorder.startRecording();
                  setIsLoading(false);
                  setIsRecording(true);
              } catch (e) {
                  console.error(e);
                  setIsLoading(false);
              }
          } }
     
  };
  return (
      <React.Fragment>
          <button type="button" disabled={isLoading}  onClick={() => record()}>
              {hasRecording ? "Clear" : isRecording ? "Stop" : "Record"}
          </button>
          {hasRecording ?
              <ul style={{ listStyle: "none", padding: 20, textAlign: "center"}}>
              <li key={recordings[0]} style={{ display: "inline-block" }}>
                  <audio src={recordings[0]} controls />
              </li>
          </ul>: null}
      </React.Fragment>
  )
}

export default Main;