import React, { useState } from 'react';
import { db, app } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import './Pop.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { send } from 'emailjs-com';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import {IoInformationCircleOutline} from "react-icons/io5";
import Modal from 'react-bootstrap/Modal';

import vmsg from "vmsg";
const recorder = new vmsg.Recorder({
    wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
});

// when called, retrieves all data for currently displayed name.
function Main (props) {
    const [isPopped, togglePop] = useState(false)
    const [isInfo, toggleInfo] = useState(false)
    return(
        <div style={{margin:10,marginBottom:50}}>
            <p><em class="llink" style={{ "font-size": "17px" }} onClick={() => togglePop(!isPopped)}>Submit an update, a correction, or a recording for this name </em><em class="llink" onClick={() => toggleInfo(!isInfo)}><IoInformationCircleOutline/></em></p>
            {isPopped ? <PopUp isPopped={isPopped} togglePop={togglePop} value={props.value}/> : null}
            {isInfo ? <InfoPop isInfo={isInfo} toggleInfo={toggleInfo}/>: null}
        </div>
    )
}

function InfoPop (props) {
    const handleClose = () => props.toggleInfo(false);
    const handleShow = () => props.toggleInfo(true);
  
    return(
        <div>
        <Modal show={handleShow} onHide={handleClose} centered>
          <Modal.Header closeButton>
            {/* <Modal.Title>Phonotactics</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
          <div>All of our names are user-submitted. You can help us improve! Fill out this form to help fill a gap in information or to correct a mistake.</div>
          <br></br>
          <div>We are interested in the following information for each name: IPA, commonly associated gender, languages the name is associated with, the name written in non-Roman orthography, recordings of the name, alternate spellings of the name, shortened/lengthened forms of the name, alternate pronunciations of the name, cultures the name is associated with (if different than associated languages), famous people with the name, other notes (such as historical or cultural information).</div>
          <br></br>
          <div>If you would like your name to appear with this name as a thanks for your help, please include that in your submission as well.</div>
          </Modal.Body>
        </Modal>
      </div>
    )
}

function PopUp (props) {
    const handleClose = () => props.togglePop(false);
    const handleShow = () => props.togglePop(true);
  
    const [waveOne, setWaveOne] = useState();
    const [nm, setName] = useState("")
    const [toSend,setToSend] = useState({
        from_name: '',
        to_name: '',
        message: '',
        reply_to: '',
    });
    
    const [audioDetails, setAudioDetails] = useState([]);
    const [hasRecording, setHasRecording] = useState(false);
    console.log(props.value);

    //information is emailed to diversenamesgenerator@gmail.com upon submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const date = Date.now();
        if(toSend.message == ""){
            alert("A message is required")
            return
        }
        else {
        const ms = "Submitter would like credit under the name " + nm + "\n " + "message: " + toSend.message + "\n correction for name" + props.value.name;
        setToSend(({message: ms}))
        // (e) => setToSend(() => ({message:"Correction on Name " + props.value.name + ": " + e.target.value}))
        console.log(toSend);
        await send(
          'service_apjqtkj',
          'template_jim4xna',
          toSend,
          '0VFj-B1xI5U4ZDeJe'
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert("Your edit is under review!")
            props.togglePop(!props.isPopped)
        })
        .catch((err) => {
            alert("Error in uploading media. Please try again another time.")
            console.log('FAILED...', err);
        });
         //sending audio
         if(hasRecording)
        {
        const ch = audioDetails;
        console.log(audioDetails);
        let nm = "correctionsAdditions/" + props.value.name + "_" + date + ".wav";
        const metadata = {
            contentType: 'audio/wav',
        };
        const storage = getStorage();
        const storageRef = ref(storage, nm);
        await(uploadBytes(storageRef, ch, metadata).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        }))
        }
    }
    };
    
    return(
        <div>
            <Modal show={handleShow} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>   
                        <label>Please explain the correction or addition to <b>{props.value.name}</b> that you would like to contribute *</label>
                        <p>Attributes we are looking for include name, IPA, famous people with this name, languages or cultures associated with the name,
                        any alternate spellings, alternate pronunciations, and any additional notes on the name
                        </p>
                            <div>
                            <textarea class="textarea" rows="8" id="TITLE" onChange={(e) => setToSend(() => ({message:"submitter " + nm + " Correction on Name " + props.value.name + ": " + e.target.value}))} required></textarea>
                            </div>
                        <label>Optionally submit an audio file of your pronounciation of {props.value.name}</label>
                        <div class="recorder">
                            <RecApp setAudioDetails={setAudioDetails} hasRecording={hasRecording} setHasRecording={setHasRecording}/>
                        </div>
                        <label>If you would like recognition in our app for contributing to this name, what is your name?</label>
                        <p></p>
                        <input type="text" onChange={(e) => setName(e.target.value)}></input>
                        <p></p>
                        <button type="submit" onClick={"this.disabled=true; this.value='sending…';"}>submit</button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

function RecApp (props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recordings, setRecordings] = useState([]);
    const [audio, setAudio] = useState([]);
    // const [hasRecording, setHasRecording] = useState(false);
  
    const record = async () => {
        setIsLoading(true)
        if (props.hasRecording) {
            setIsLoading(false);
            setIsRecording(false);
            setRecordings([]);
            setAudio([])
            props.setHasRecording(false)
            //*
            props.setAudioDetails([]);
            //*
        }
        else {
            if (isRecording) {
                const blob = await recorder.stopRecording();
                setIsLoading(false)
                setIsRecording(false)
                setRecordings(recordings.concat(URL.createObjectURL(blob)))
                setAudio(blob)
                props.setHasRecording(true)
                props.setAudioDetails(blob);
            }
            else {
                try {
                    await recorder.initAudio();
                    await recorder.initWorker();
                    recorder.startRecording();
                    setIsLoading(false);
                    setIsRecording(true);
                } catch (e) {
                    console.error(e);
                    setIsLoading(false);
                }
            } }
       
    };
    const upload = async () => {
        // const ch = audio;
        // console.log(audio);
        // let nm = database + name + "_" + Date.now() + ".wav";
        // const metadata = {
        //     contentType: 'audio/wav',
        // };
        // const storage = getStorage();
        // const storageRef = ref(storage, nm);
        // uploadBytes(storageRef, ch, metadata).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        // });
    };
    return (
        <React.Fragment>
            <button type="button" disabled={isLoading}  onClick={() => record()}>
                {props.hasRecording ? "Clear" : isRecording ? "Stop" : "Record"}
            </button>
            {/* <button disabled={!hasRecording} onClick={() => upload()}>
                {"Upload"}
            </button> */}
            {props.hasRecording ?
                <ul style={{ listStyle: "none", padding: 20, textAlign: "center"}}>
                <li key={recordings[0]} style={{ display: "inline-block" }}>
                    <audio src={recordings[0]} controls />
                </li>
            </ul>: null}
        </React.Fragment>
    )
  }

export default Main;